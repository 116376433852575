import { transitions } from "polished"
import styled from "styled-components"
import Bullet from "../images/bullet.svg"

export const Callout = styled.h1`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.light};
  font-size: clamp(2.22222222rem, ((88 / 1440) * 100vw), 4.88888889rem);
  line-height: 1.2;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
  letter-spacing: -0.05em;
`

export const H1 = styled.h1`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.bold};
  font-size: 4.8rem;
  line-height: 1;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
`

export const H2 = styled.h2`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.normal};
  font-size: 3.2rem;
  line-height: 1.2;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
`

export const H3 = styled.h3`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.normal};
  font-size: 2.4rem;
  line-height: 1.2;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
`

export const H4 = styled.h4`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.bold};
  font-size: 1.8rem;
  line-height: 1.2;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
`

export const TableHead = styled(H3)`
  text-align: center;
`

export const Table = styled.table`
  border-collapse: collapse;
  margin-block: 1.5em;

  td,
  th {
    text-align: center;
    padding: 1em 0.25em;
    border: 1px solid ${({ theme }) => theme.colors.grey};
  }

  td {
    font-size: 0.75em;

    a {
      color: ${({ theme }) => theme.colors.brand};
      ${transitions(["color"], ".2s ease")};
      word-break: break-all;

      &:hover {
        color: inherit;
      }
    }
  }

  th {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    background-color: ${({ theme }) => theme.colors.greyDark};
  }
`

export const P = styled.p`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.normal};
  font-size: 1.8rem;
  line-height: 1.5;
  ${({ color }) => color && `color: ${color}`}
  margin: ${({ margin }) => (margin ? margin : 0)};

  + p {
    margin-top: 1.4rem;
  }

  > a {
    color: ${({ theme }) => theme.colors.brand};
    ${transitions(["color"], ".2s ease")};

    &:hover {
      color: inherit;
    }
  }
`

export const Small = styled.small`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 1.42;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
`

export const Smaller = styled.small`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 1.2;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
`

export const Caption = styled.small`
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? fontWeight : theme.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 1.333;
  text-transform: uppercase;
  color: ${({ color, theme }) => (color ? color : theme.colors.white)};
  margin: ${({ margin }) => (margin ? margin : 0)};
`
export const Blockquote = styled.blockquote`
  margin: ${({ margin }) => (margin ? margin : `5.5rem 0`)};

  > p {
    font-weight: ${({ fontWeight, theme }) =>
      fontWeight ? fontWeight : theme.fontWeights.normal};
    font-size: clamp(2rem, ((24 / 1440) * 100vw), 2.4rem);
    line-height: 1.2;
    color: ${({ color, theme }) => (color ? color : theme.colors.white)};
    margin: 0;

    &:before,
    &:after {
      font-family: serif;
      font-size: 1.25em;
      line-height: 1;
      vertical-align: middle;
    }

    &:before {
      content: "“ ";
    }

    &:after {
      content: " ”";
    }
  }
`

export const UL = styled.ul`
  list-style-image: url(${Bullet});
  margin: 4rem 0 4rem 1em;

  li {
    padding-left: 1.2rem;
  }
`

export const Address = styled.address`
  font-style: normal;
`
