import React, { createContext, useReducer } from "react"

const defaultState = {
  earlyAccessPopup: false,
  mobileMenu: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_EARLY_ACCESS_POPUP": {
      return {
        ...state,
        earlyAccessPopup: !state.earlyAccessPopup,
      }
    }
    case "TOGGLE_MOBILE_MENU": {
      return {
        ...state,
        mobileMenu: !state.mobileMenu,
      }
    }
    case "CLOSE_MOBILE_MENU": {
      return {
        ...state,
        mobileMenu: false,
      }
    }
    default:
      throw new Error("Bad action Type")
  }
}

const PhiProvider = ({ props, children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  return (
    <PhiStateContext.Provider value={state}>
      <PhiDispatchContext.Provider value={dispatch}>
        {children}
      </PhiDispatchContext.Provider>
    </PhiStateContext.Provider>
  )
}

const PhiStateContext = createContext(defaultState)
const PhiDispatchContext = createContext()

export { PhiProvider, PhiStateContext, PhiDispatchContext }
