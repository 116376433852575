import React from "react"
import { PhiProvider } from "./src/context"
import { ThemeProvider } from "styled-components"
import { theme, GlobalStyle } from "./src/styles"
import "leaflet/dist/leaflet.css"
import "./static/plyr.css"

export const wrapRootElement = ({ element }) => {
  return <PhiProvider>{element}</PhiProvider>
}

export const wrapPageElement = ({ element }) => (
  <>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  </>
)
