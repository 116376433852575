import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
  box-sizing: border-box;
  /* outline: 1px solid red; */

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

  html {
    font-size: 10px;
    height: stretch;
  }

  body {
    color: ${(props) => props.theme.colors.white};
    font: ${(props) =>
      props.theme.fontWeights
        .normal} 1.8rem / 1.333 'Inter', system-ui, sans-serif;
     -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   min-inline-size: 100%;
  min-block-size: 100%;
  margin: 0;
  background-color: ${(props) => props.theme.colors.black}
  }

  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    min-height: 100%;
  }

  #gatsby-focus-wrapper {
    position: relative;
  }

  img,
  svg {
    vertical-align: middle;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  button {
    border: 0;
    background: none;
    cursor: pointer;
  }

  b, strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  figure {
    margin: 0;

    img {
      max-width: 100%;
      height: auto;
    }
  }
`

export default GlobalStyle
