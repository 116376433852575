export const theme = {
    colors: {
        brandLighter: "hsla(44, 93%, 78%, 1)",
        brandLight: "hsla(44, 93%, 68%, 1)",
        brand: "hsla(44, 93%, 57%, 1)",
        brandDark: "hsla(44, 93%, 43%, 1)",
        black: "hsla(0, 0%, 3%, 1)",
        offBlack: "hsla(0, 0%, 8%, 1)",
        greyDark: "hsla(0, 0%, 11%, 1)",
        grey: "hsla(0, 0%, 33%, 1)",
        greyLight: "hsla(0, 0%, 56%, 1)",
        offWhite: "hsla(0, 0%, 75%, 1)",
        white: "hsla(0, 0%, 96%, 1)",
        transparentW: "hsla(0, 0%, 100%, 0)",
        transparentB: "hsla(0, 0%, 0%, 0)",
    } as const,
    opacity: {
        faded: 0.7,
        extraFaded: 0.55,
        muted: 0.4,
    } as const,
    fontWeights: {
        thin: 100,
        light: 300,
        normal: 400,
        bold: 700,
    } as const,
    zIndexes: {
        header: 1000,
        mobileMenu: 1500,
        filters: 1100,
    } as const,
} as const;
