// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-instructions-tsx": () => import("./../../../src/pages/instructions.tsx" /* webpackChunkName: "component---src-pages-instructions-tsx" */),
  "component---src-pages-knowledge-learn-js": () => import("./../../../src/pages/knowledge/learn.js" /* webpackChunkName: "component---src-pages-knowledge-learn-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-updates-tsx": () => import("./../../../src/pages/updates.tsx" /* webpackChunkName: "component---src-pages-updates-tsx" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tool-js": () => import("./../../../src/templates/tool.js" /* webpackChunkName: "component---src-templates-tool-js" */)
}

